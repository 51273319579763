// keep for future open positions

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import CareersDetailsView from '../../components/CareersDetails';

const CareersDetails = ({ location, data }) => (
  <CareersDetailsView
    location={location}
    title="Angular Front-end Developer - Software Careers | MCRO"
    description="Angular Javascript Developer at MCRO. Be part of the best front-end team in Cluj Napoca, Romania "
    metaContent="angular, ionic, javascript, nodejs"
    id="angular"
    jobTitle="Angular JS Egineer"
    jobDescription={`If you are a forward thinking, problem solver with a strong passion for JavaScript at
  MCRO you will collaborate with an extremely talented and professional team of engineers that will boost
  your career towards success and will help you grow and deliver the best quality for top clients and
  innovative products.`}
    mailHref="mailto:jobs@mcro.tech?subject=Angular Job Application"
    roles={[
      'Develop new features or maintain an existing platform',
      'Sync up on a daily basis with a distributed team and respect the coding standards required',
      'Deliver in a controlled agile environment']}
    offerings={[
      'Competitive compensation',
      'Possibility to work remote when desired',
      'Work with the best professionals in the area',
      'Great work environment in our Cluj Napoca-Office',
      'Private healthcare insurance for employees'
    ]}
    requirements={[
      'Great communication skills verbal and written English',
      'Experience with heavy UI front-end applications',
      'Very good understanding of Core Javascript including ES5 ES6 ES7',
      'Strong experience with Angular, AngularJS(&lt;2) and Ionic',
      'Strong experience with creating scalable and roubust architecture with Angular using common patterns',
      `An eye for design and strong experience in applying responsive
      theming using HTML5 and CSS3 (custom, boostrap, foundation or others)`,
      'Experience with NodeJS packagers like Webpack, Gulp',
      'Experience with NodeJS on server-side is a plus'
    ]}
    imageElement={data}
  />
);

CareersDetails.propTypes = {
  location: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({})
};

CareersDetails.defaultProps = {
  data: null
};

export default CareersDetails;

export const query = graphql`
  query angularCareerPage {
    file(relativePath: { eq: "careers-details-banner.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440)  {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
